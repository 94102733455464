import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { CinemaContext } from '../components/cinema-finder/cinema-context';
import { CookieConsentPrompt } from '../components/cookie-consent/cookie-consent';
import { FilmFinder } from '../components/film-finder/film-finder';
import { Newsletter } from '../components/newsletter/newsletter';

import './layout.scss';
import { graphQuery } from '../utils/AcGraphApi';

interface PropsTypes {
  children?: any;
  content?: any;
}

export const Layout = (props: PropsTypes) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      allContentfulMenu {
        nodes {
          menuType
          pages {
            pageName
            pageUrl
          }
        }
      }
      contentfulWebsiteSettings {
        headerIconLink {
          image {
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
            description
          }
          name
          openInNewTab
          hideName
          link
        }
        footerIconLinks {
          image {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
            description
          }
          name
          link
          openInNewTab
          hideName
        }
        logo {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
          description
        }
        websiteName
      }
    }
  `);

  const menus =
    data.allContentfulMenu && data.allContentfulMenu.nodes
      ? data.allContentfulMenu.nodes
      : [];
  const headerMenu = menus.find((item: any) => {
    return item.menuType === 'header';
  });

  const footerMenu = menus.find((item: any) => {
    return item.menuType === 'footer';
  });

  const settings = data.contentfulWebsiteSettings || {};

  const [isNavToggled, toggleNav] = React.useState(false);
  const pageNav = React.useRef(null);

  const handleNavBtnClick = () => {
    const menuList: any =
      pageNav !== null && pageNav.current !== null ? pageNav.current : null;

    if (menuList !== null) {
      const jQuery = require('jquery');
      const slideSpeed = 400;

      if (isNavToggled) {
        jQuery(menuList).slideUp(slideSpeed);
      } else {
        jQuery(menuList).slideDown(slideSpeed);
      }
    }

    toggleNav(!isNavToggled);
  };

  const [cinemas, setCinemas] = React.useState([]);
  const [calledGetSelectCinemas, setCalledGetSelectCinemas] = React.useState(
    false
  );

  const getSelectCinemas = () => {
    if (calledGetSelectCinemas) {
      return;
    }

    setCalledGetSelectCinemas(true);

    const limit =
      typeof process.env.GATSBY_API_LIMIT !== 'undefined'
        ? process.env.GATSBY_API_LIMIT
        : '120';
    const companyId =
      typeof process.env.GATSBY_API_ID !== 'undefined'
        ? process.env.GATSBY_API_ID
        : '';

    const tempCinemas: any = [];

    const makeCall = (endCursor = null) => {
      const query = `
          query {
            theaterList(
              affiliation: {
                  activity: THEATER_TRADE_GROUP,
                  companyId: "${companyId}"
              },
              after: "${endCursor}",
              countries: [UNITED_KINGDOM],
              first: ${limit},
              order: [ALPHABETICAL]
            ) {
              pageInfo {
                hasNextPage
                endCursor
              }
              edges {
                node {
                  name
                  location {
                    city
                  }
                }
              }
          }
          }
        `;
      graphQuery(query, 'theaterList')
        .then((r) => {
          Array.prototype.push.apply(tempCinemas, r.data.theaterList.edges);

          if (r.data.theaterList.pageInfo.hasNextPage) {
            makeCall(r.data.theaterList.pageInfo.endCursor);
          } else {
            setCinemas(tempCinemas);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    };

    makeCall();
  };

  React.useEffect(() => {
    getSelectCinemas();
  }, [cinemas]);

  return (
    <CinemaContext.Provider value={cinemas}>
      <Helmet defer={false}>
        <html lang="en" />
        {props.content.seoTitle && <title>{props.content.seoTitle}</title>}
        {props.content.seoDescription && (
          <meta
            name="description"
            content={props.content.seoDescription.seoDescription}
          />
        )}
        <meta
          name="google-site-verification"
          content="xO_bSIbYovRVbwbNrdnKkGWtAHd-M8koGexsFCbbLEM"
        />
      </Helmet>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-KQ59MWCW"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      <a href="#content" className="skip-link">
        Skip to content
      </a>
      <CookieConsentPrompt />
      <div className="page">
        <header className="page-header">
          <div className="contain contain--2">
            <div className="contain__column page-header__logo">
              <a
                aria-label="Return to the homepage"
                className="page-header__logo__link"
                href="/"
              >
                {settings.logo &&
                settings.logo.description &&
                settings.logo.file &&
                settings.logo.file.url ? (
                  <img
                    alt={settings.logo.description}
                    className="page-header__logo__image"
                    loading="lazy"
                    src={settings.logo.file.url}
                    width={settings.logo.file.details.width}
                    height={settings.logo.file.details.height}
                  />
                ) : (
                  settings.websiteName
                )}
              </a>
            </div>
            <div className="contain__column page-header__link">
              {settings.headerIconLink && (
                <a
                  aria-label={
                    settings.headerIconLink.name +
                    (settings.headerIconLink.openInNewTab === null
                      ? ''
                      : ' (opens in a new window)')
                  }
                  className="icon-link"
                  href={settings.headerIconLink.link}
                  rel={
                    settings.headerIconLink.openInNewTab === null
                      ? ''
                      : 'noopener noreferrer'
                  }
                  target={
                    settings.headerIconLink.openInNewTab === null
                      ? '_self'
                      : '_blank'
                  }
                >
                  {settings.headerIconLink.hideName === null && (
                    <span className="icon-link__text">
                      {settings.headerIconLink.name}
                    </span>
                  )}
                  {settings.headerIconLink.image &&
                    settings.headerIconLink.image.description && (
                      <img
                        alt={settings.headerIconLink.image.description}
                        className="icon-link__image"
                        src={settings.headerIconLink.image.file.url}
                        width={settings.headerIconLink.image.file.details.width}
                        height={
                          settings.headerIconLink.image.file.details.height
                        }
                      />
                    )}
                </a>
              )}
              {headerMenu && headerMenu.pages && headerMenu.pages.length > 0 && (
                <button
                  aria-controls="menu"
                  aria-expanded={isNavToggled}
                  className="nav-btn"
                  onClick={handleNavBtnClick}
                  type="button"
                >
                  <span aria-hidden="true" className="nav-btn__icon">
                    <span className="nav-btn__icon__top" />
                    <span className="nav-btn__icon__middle" />
                    <span className="nav-btn__icon__bottom" />
                  </span>
                  <span>menu</span>
                </button>
              )}
            </div>
          </div>
        </header>
        {headerMenu && headerMenu.pages && headerMenu.pages.length > 0 && (
          <nav
            className="page-nav"
            id="menu"
            ref={pageNav}
            aria-label="primary navigation"
          >
            <ul className="contain page-nav__list">
              {headerMenu.pages.map((item: any, index: number) => {
                const ariaCurrent: any = {};

                if (props.content && props.content.pageUrl === item.pageUrl) {
                  ariaCurrent['aria-current'] = 'page';
                }

                return (
                  <li className="page-nav__list__item" key={index}>
                    <a
                      {...ariaCurrent}
                      className="page-nav__list__item__link"
                      href={`/${item.pageUrl}/`}
                    >
                      {item.pageName}
                    </a>
                  </li>
                );
              })}
            </ul>
          </nav>
        )}
        <aside className="page-qb" aria-label="location and film finder">
          <FilmFinder />
        </aside>
        <main id="content" className="page-content">
          {props.children}
        </main>
        <aside className="page-aside" aria-label="newsletter signup">
          <Newsletter />
        </aside>
        <aside className="page-aside social" aria-label="social media">
          {settings.footerIconLinks &&
            settings.footerIconLinks.length > 0 &&
            settings.footerIconLinks.map((item: any, index: number) => (
              <a
                aria-label={
                  item.name +
                  (item.openInNewTab === null ? '' : ' (opens in a new window)')
                }
                className="icon-link"
                href={item.link}
                key={index}
                rel={item.openInNewTab === null ? '' : 'noopener noreferrer'}
                target={item.openInNewTab === null ? '_self' : '_blank'}
              >
                {item.hideName === null && (
                  <span className="icon-link__text">{item.name}</span>
                )}
                {item.image && item.image.description && (
                  <img
                    alt={item.image.description}
                    className="icon-link__image"
                    loading="lazy"
                    src={`${item.image.file.url}?w=110`}
                    width={item.image.file.details.width}
                    height={item.image.file.details.height}
                  />
                )}
              </a>
            ))}
        </aside>
        <footer className="page-footer">
          <div className="contain contain--2">
            <div className="contain__column page-footer__copyright">
              &copy; {settings.websiteName} {new Date().getFullYear()}
            </div>
            {footerMenu && footerMenu.pages && footerMenu.pages.length > 0 && (
              <nav
                className="contain__column page-footer__nav"
                aria-label="secondary navigation"
              >
                <ul className="page-footer__nav__list">
                  {footerMenu.pages.map((item: any, index: number) => {
                    const ariaCurrent: any = {};

                    if (
                      props.content &&
                      props.content.pageUrl === item.pageUrl
                    ) {
                      ariaCurrent['aria-current'] = 'page';
                    }

                    return (
                      <li className="page-footer__nav__list__item" key={index}>
                        <a
                          {...ariaCurrent}
                          className="page-footer__nav__list__item__link"
                          href={`/${item.pageUrl}/`}
                        >
                          {item.pageName}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            )}
          </div>
        </footer>
      </div>
    </CinemaContext.Provider>
  );
};
