import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { MarkupOptions } from '../../utilities';

import './list.scss';

interface PropsTypes {
  heading?: string;
  pageContext?: any;
  text?: any;
}

export const List = (props: PropsTypes) => {
  const data = useStaticQuery(graphql`
    query ListQuery {
      certs: allContentfulCertificate(sort: { order: ASC, fields: sortOrder }) {
        nodes {
          name
          image {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            description
          }
          alias
          description {
            raw
          }
        }
      }
      partners: allContentfulPartner(sort: { order: ASC, fields: name }) {
        nodes {
          image {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
            description
          }
          name
          link
          description {
            raw
          }
        }
      }
      settings: contentfulWebsiteSettings {
        placeholderImage {
          description
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
        }
      }
    }
  `);

  if (
    props.pageContext &&
    props.pageContext.type &&
    typeof props.pageContext.items === 'undefined'
  ) {
    switch (props.pageContext.type) {
      case 'certification list':
        props.pageContext.items = data.certs.nodes;
        break;
      case 'partners list':
        props.pageContext.items = data.partners.nodes;
        break;
    }
  }

  const pagingItems = [];

  if (
    props.pageContext &&
    props.pageContext.paging === true &&
    props.pageContext.pageLength > 1
  ) {
    for (let p = 0; p < props.pageContext.pageLength; p++) {
      let pagingUrl = `/${props.pageContext.pageUrl}/`;
      let currentItem = ``;

      if (p > 0) {
        pagingUrl += `page-${p + 1}`;
      }

      if (p + 1 === props.pageContext.currentPage) {
        currentItem = `aria-current="true"`;
      }

      pagingItems.push(`
                <li class="list__paging__list__item js-paging-item">
                    <a
                        ${currentItem}
                        aria-label="Page ${p + 1} of ${
        props.pageContext.pageLength
      }"
                        class="list__paging__list__item__link"
                        href="${pagingUrl}"
                    >
                        ${p + 1}
                    </a>
                </li>
            `);
    }
  }

  const [isPagingSetup, setIsPagingSetup] = React.useState(false);

  const setupPaging = () => {
    setIsPagingSetup(true);

    const paging = document.querySelector('.js-paging');

    if (paging === null) {
      return;
    }

    const pages = paging.querySelectorAll('.js-paging-item');

    if (pages.length === 0) {
      return;
    }

    for (let p = 0; p < pages.length; p++) {
      if (
        p === 0 ||
        p === pages.length - 1 ||
        p === props.pageContext.currentPage ||
        p === props.pageContext.currentPage + 1 ||
        p === props.pageContext.currentPage - 1 ||
        p === props.pageContext.currentPage - 2 ||
        p === props.pageContext.currentPage - 3
      ) {
        continue;
      }

      pages[p].className += ' js-paging-item-hidden';
    }
  };

  React.useEffect(() => {
    if (isPagingSetup === false) {
      setupPaging();
    }
  });

  return (
    <div className="contain">
      <div className="contain__column">
        {props.heading && <h2 className="heading">{props.heading}</h2>}

        {props.text && (
          <div>
            {documentToReactComponents(JSON.parse(props.text), MarkupOptions)}
          </div>
        )}

        {props.pageContext &&
          props.pageContext.items &&
          props.pageContext.items.length > 0 && (
            <div className="list">
              {props.pageContext.items.map((item: any, index: number) => {
                const image = {
                  alt: data.settings.placeholderImage.description,
                  class: 'placeholder-image',
                  height:
                    data.settings.placeholderImage.file.details.image.height,
                  url: `${data.settings.placeholderImage.file.url}`,
                  width:
                    data.settings.placeholderImage.file.details.image.width,
                };

                if (
                  item.pageSummaryImage &&
                  item.pageSummaryImage.description
                ) {
                  image.url = `${item.pageSummaryImage.file.url}?w=703`;
                  image.alt = item.pageSummaryImage.description;
                  image.height =
                    item.pageSummaryImage.file.details.image.height;
                  image.width = item.pageSummaryImage.file.details.image.width;
                  image.class = '';
                }

                if (item.image && item.image.description) {
                  image.url = `${item.image.file.url}?w=703`;
                  image.alt = item.image.description;
                  image.height = item.image.file.details.image.height;
                  image.width = item.image.file.details.image.width;
                  image.class = '';
                }

                return (
                  <div key={index} className="list__item">
                    <div className="list__item__image">
                      <img
                        alt={image.alt}
                        className={image.class}
                        loading="lazy"
                        src={image.url}
                        height={image.height}
                        width={image.width}
                      />
                    </div>
                    <div className="list__item__content">
                      {item.pageHeading && (
                        <h2 className="list__item__content__heading">
                          {item.pageHeading}
                        </h2>
                      )}
                      {item.name && (
                        <h2 className="list__item__content__heading">
                          {item.name}
                        </h2>
                      )}
                      {item.link && <p>{item.link}</p>}
                      {item.publishedDate && (
                        <p className="list__item__content__date">
                          Published on {item.publishedDate}
                        </p>
                      )}
                      {item.pageSummary && (
                        <div className="list__item__content__text">
                          {item.pageSummary.pageSummary}
                        </div>
                      )}
                      {item.description && (
                        <div className="list__item__content__text">
                          {documentToReactComponents(
                            JSON.parse(item.description.raw),
                            MarkupOptions
                          )}
                        </div>
                      )}
                      {props.pageContext.pageUrl && (
                        <a
                          aria-label={`Read more about ${item.pageHeading}`}
                          className="list__item__content__link btn"
                          href={`/${props.pageContext.pageUrl}/${item.pageUrl}/`}
                        >
                          Read More
                        </a>
                      )}
                      {item.link && (
                        <a
                          aria-label={`Visit ${item.name} (opens in a new tab)`}
                          className="list__item__content__link btn"
                          target="_blank"
                          href={item.link}
                        >
                          {`Visit ${item.name}`}
                        </a>
                      )}
                    </div>
                  </div>
                );
              })}
              {props.pageContext.paging && (
                <nav
                  aria-label="list paging navigation"
                  className="list__paging js-paging"
                >
                  <ul
                    className="list__paging__list"
                    dangerouslySetInnerHTML={{ __html: pagingItems.join('') }}
                  />
                </nav>
              )}
            </div>
          )}
      </div>
    </div>
  );
};
