import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import {
  GetFriendlyName,
  MarkupOptions,
  GetUrlParameter,
} from '../../utilities';
import {
  FilmType,
  NewShowtimeType,
  searchCinemaShowtimes,
} from './searchCinemasAndShowtimes';
import { createContentGroupItem } from '../../components/content-group/content-group';
import getPictureUrl from '../../utils/AcGraphApi';
import {
  API_TAGS_TO_FORM_FILTERS,
  API_TAGS,
  FORM_FILTERS_TO_API_FILTERS,
  FORM_FILTERS,
} from '../../utils/tags';
const companyId =
  typeof process.env.GATSBY_API_ID !== 'undefined'
    ? process.env.GATSBY_API_ID
    : '';

const imageWidth = 300;
const imageHeight = 450;
const screeningType = GetUrlParameter('screeningType');
let showtimesFilter = '';
if (
  screeningType &&
  Object.prototype.hasOwnProperty.call(
    FORM_FILTERS_TO_API_FILTERS,
    screeningType
  )
) {
  const sc = screeningType as FORM_FILTERS;
  showtimesFilter = FORM_FILTERS_TO_API_FILTERS[sc];
}

interface FilmResultsItemProps {
  id: string;
  name: string;
  url: string;
  coordinates?: {
    distance: string;
  };
  showtimesData?: FilmType[];
  defaultImage: {
    alt: string;
    class: string;
    height: number;
    url: string;
    width: number;
  };
}

const FilmResultsItem = (props: FilmResultsItemProps) => {
  const data = useStaticQuery(graphql`
    query FilmResultsItemQuery {
      attributes: allContentfulScreeningType(
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          name
          image {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          alias
          link {
            pageUrl
          }
        }
      }
      filmFinder: contentfulFilmFinder {
        noResultsMessage {
          raw
        }
      }
    }
  `);

  const attributeData: any = {};

  data.attributes.nodes.forEach((item: any) => {
    attributeData[item.alias] = item;
  });

  const friendlyName = GetFriendlyName(props.name);

  const [showtimesData, setShowtimesData] = React.useState(
    props.showtimesData ? props.showtimesData : null
  );

  const itemFilmsImage = (item: FilmType) => {
    let image = props.defaultImage;

    if (item.movie.localePoster && item.movie.localePoster.url) {
      image = {
        alt: `${item.movie.localeTitle} poster`,
        class: '',
        height: imageHeight,
        url: getPictureUrl(item.movie.localePoster.url, {
          resize: {
            height: imageHeight,
            width: imageWidth,
          },
        }),
        width: imageWidth,
      };
    }

    return (
      <img
        alt={image.alt}
        src={image.url}
        className={image.class}
        width={image.width}
        height={image.height}
      />
    );
  };

  const itemFilmsShowtimes = (item: FilmType, cinema: FilmResultsItemProps) => {
    if (
      typeof item.showtimesFormatted !== 'undefined' &&
      item.showtimesFormatted !== null &&
      item.showtimesFormatted.length > 0
    ) {
      return (
        <React.Fragment>
          {item.showtimesFormatted.map(
            (showtime: NewShowtimeType, showtimeIndex: number) => (
              <div
                className="film-results__films__item__showtimes__date"
                key={showtimeIndex}
              >
                <div className="film-results__films__item__showtimes__date__title">
                  {showtime.dateFormatted}
                </div>
                <div className="film-results__films__item__showtimes__date__times">
                  {showtime.times.map((timeItem, timeIndex: number) => {
                    let linkArialLabel = `vist website for ${cinema.name} (opens in a new tab)`;
                    let linkUrl = cinema.url;

                    if (timeItem.url !== '') {
                      linkArialLabel = `${timeItem.time} - ${showtime.dateFormatted} - buy tickets for ${item.movie.localeTitle} (opens in a new tab)`;
                      linkUrl = timeItem.url;
                    }

                    return (
                      <div
                        className="film-results__films__item__showtimes__date__times__item"
                        key={timeIndex}
                      >
                        <a
                          aria-label={linkArialLabel}
                          href={linkUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="film-results__films__item__showtimes__date__times__link btn"
                        >
                          {timeItem.time}
                        </a>
                        <div className="film-results__films__item__showtimes__date__times__attributes">
                          {timeItem.tags &&
                            timeItem.tags.map((tag, itemIndex) => {
                              const screeningTypeClean = tag.replace(
                                /\./g,
                                '_'
                              ) as API_TAGS;
                              if (
                                Object.prototype.hasOwnProperty.call(
                                  API_TAGS_TO_FORM_FILTERS,
                                  screeningTypeClean
                                ) &&
                                Object.prototype.hasOwnProperty.call(
                                  attributeData,
                                  API_TAGS_TO_FORM_FILTERS[screeningTypeClean]
                                )
                              ) {
                                const attr =
                                  attributeData[
                                    API_TAGS_TO_FORM_FILTERS[screeningTypeClean]
                                  ];
                                return (
                                  <img
                                    key={itemIndex}
                                    className="film-results__films__item__showtimes__date__times__attributes__item"
                                    alt={attr.image.description}
                                    title={attr.name}
                                    loading="lazy"
                                    src={`${attr.image.file.url}?w=40`}
                                  />
                                );
                              }
                              return null;
                            })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          )}
        </React.Fragment>
      );
    }
    return null;
  };

  return (
    <React.Fragment>
      {createContentGroupItem(
        [
          {
            beforeSlide: (callback: any) => {
              if (showtimesData) {
                callback();
                return;
              }
              searchCinemaShowtimes(companyId, props.id, showtimesFilter)
                .then((r) => {
                  setShowtimesData(r);
                  callback();
                })
                .catch((e) => {
                  // console.error(e);
                  callback();
                });
            },
            content: (
              <div className="film-results__results__item__content">
                <div className="film-results__films">
                  {showtimesData &&
                    showtimesData.map((item: FilmType, itemIndex: number) => (
                      <div
                        className="film-results__films__item"
                        key={itemIndex}
                      >
                        <div className="film-results__films__item__image">
                          <div className="film-results__films__item__image__contain">
                            {itemFilmsImage(item)}
                          </div>
                        </div>
                        <div className="film-results__films__item__content">
                          <h4 className="film-results__films__item__title">
                            {item.movie.localeTitle}
                          </h4>
                          <p className="film-results__films__item__cert">
                            {item.movie.localeCertificate}
                          </p>
                          <div className="film-results__films__item__showtimes">
                            {itemFilmsShowtimes(item, props)}
                          </div>
                        </div>
                      </div>
                    ))}

                  {(showtimesData === null || showtimesData.length === 0) &&
                    data.filmFinder &&
                    data.filmFinder.noResultsMessage &&
                    data.filmFinder.noResultsMessage.raw && (
                      <React.Fragment>
                        {documentToReactComponents(
                          JSON.parse(data.filmFinder.noResultsMessage.raw),
                          MarkupOptions
                        )}
                      </React.Fragment>
                    )}
                </div>
              </div>
            ),
            id: `film-${friendlyName}`,
            isOpen: null,
            title: (
              <span className="film-results__results__item__title">
                <span className="film-results__results__item__title__text">
                  {props.name}
                </span>
                {props.coordinates && props.coordinates.distance && (
                  <span className="film-results__results__item__title__subtext">
                    {props.coordinates.distance} miles
                  </span>
                )}
              </span>
            ),
          },
        ],
        MarkupOptions
      )}
    </React.Fragment>
  );
};

export default FilmResultsItem;
