import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql } from 'gatsby';
import * as React from 'react';
import { Component } from '../components/component';
import { MarkupOptions } from '../utilities';
import { Layout } from './layout';

const Page = (props: any) => {
  const content = props.data.content;
  const newsListPage = props.data.newsListPage;

  return (
    <Layout content={content}>
      {content.pageHeading ? (
        <div className="contain">
          <div className="contain__column">
            <h1 className="page-content__heading heading-highlighted">
              {content.pageHeading}
            </h1>
          </div>
        </div>
      ) : (
        <div className="page-spacer">
          <h1>Accessible Screenings UK</h1>
        </div>
      )}

      {content.pageType && content.pageType === 'news item' && (
        <div className="contain page-publish-date">
          <div className="contain__column">
            <a href={`/${newsListPage.pageUrl}/`}>
              Back to {newsListPage.pageName}
            </a>
          </div>
        </div>
      )}

      {content.publishedDate && (
        <div className="contain page-publish-date">
          <div className="contain__column">
            Published on {content.publishedDate}
          </div>
        </div>
      )}

      {content.pageContent && (
        <div className="contain">
          <div
            className={
              'contain__column' +
              (content.pageType === 'faqs' ? ' accordion js-accordion' : '')
            }
          >
            {documentToReactComponents(
              JSON.parse(content.pageContent.raw),
              MarkupOptions
            )}
          </div>
        </div>
      )}

      {content.components &&
        content.components.length > 0 &&
        content.components.map((item: any, index: number) => (
          <Component key={index} item={item} pageContext={props.pageContext} />
        ))}
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query PageQuery($pageUrl: String!) {
    newsListPage: contentfulPage(pageType: { eq: "news list" }) {
      pageName
      pageUrl
    }
    content: contentfulPage(pageUrl: { eq: $pageUrl }) {
      components {
        componentHeading
        componentText {
          raw
        }
        componentType
      }
      pageContent {
        raw
      }
      pageHeading
      pageName
      pageType
      pageUrl
      publishedDate(formatString: "DD/MM/YYYY")
      seoTitle
      seoDescription {
        seoDescription
      }
    }
  }
`;
