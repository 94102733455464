import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { MarkupOptions } from '../../utilities';
import { LoadGA } from './google-analytics';

import './cookie-consent-update.scss';

declare global {
  interface Window {
    ga: any;
  }
}

export const CookieConsentUpdate = () => {
  const data = useStaticQuery(graphql`
    query CookieConsentUpdateQuery {
      contentfulCookieConsent {
        analyticsCookiesHeading
        analyticsCookiesText {
          raw
        }
        necessaryCookiesText {
          raw
        }
        necessaryCookiesHeading
        updateHeading
        updateButtonText
      }
    }
  `);

  const content = data.contentfulCookieConsent;

  const initialSettings: any = {
    analytics: false,
  };

  const [settings, updateSettings] = React.useState(initialSettings);
  const [isSetup, changeIsSetup] = React.useState(false);

  const updateButton = React.useRef(null);

  const setup = () => {
    if (isSetup) {
      return;
    }

    changeIsSetup(true);

    if (document.cookie.indexOf('CookieConsent=') > -1) {
      try {
        const cookieSettings = JSON.parse(
          decodeURIComponent(
            document.cookie.replace(
              new RegExp(
                '(?:(?:^|.*;)\\s*' +
                  'CookieConsent' +
                  '\\s*\\=\\s*([^;]*).*$)|^.*$'
              ),
              '$1'
            )
          )
        );

        for (const key in settings) {
          if (
            settings.hasOwnProperty(key) &&
            settings[key] !== cookieSettings[key]
          ) {
            updateSettings((prevState: any) => {
              const newSettings: any = {};
              newSettings[key] = cookieSettings[key];

              return {
                ...prevState,
                ...newSettings,
              };
            });
          }
        }
      } catch (e) {
        document.cookie =
          'CookieConsent=; path=/; secure=true; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
    }
  };

  const handleChangeAnalytics = () => {
    updateSettings((prevState: any) => {
      const newSettings: any = {
        analytics: !settings.analytics,
      };

      return {
        ...prevState,
        ...newSettings,
      };
    });
  };

  const handleSave = () => {
    const tempDate = new Date();

    tempDate.setDate(tempDate.getDate() + 30);

    document.cookie =
      'CookieConsent=' +
      JSON.stringify(settings) +
      '; path=/; secure=true; SameSite=Strict; expires=' +
      tempDate.toUTCString();

    processCookieSettings();

    const prompt = document.getElementById('cookieConsent');

    if (prompt !== null) {
      const jQuery = require('jquery');

      jQuery(prompt).slideUp(600);
    }

    if (updateButton !== null && updateButton.current !== null) {
      const button: any = updateButton.current;
      const buttonText = button.innerText;

      button.innerText = 'Saving...';
      button.disabled = true;

      setTimeout(() => {
        button.innerText = 'Saved...';

        setTimeout(() => {
          button.innerText = buttonText;
          button.disabled = false;
        }, 800);
      }, 800);
    }
  };

  const processCookieSettings = () => {
    if (settings.analytics) {
      LoadGA();
    }
  };

  React.useEffect(() => {
    setup();
  }, [settings]);

  return (
    <div className="cookie-consent__update" id="cookiesettings">
      <h2
        className="cookie-consent__update__heading heading-highlighted"
        id="cookieConsentTitle"
      >
        {content && content.updateHeading}
      </h2>
      <div className="cookie-consent__update__form">
        <div className="cookie-consent__update__type">
          <h3 className="cookie-consent__update__type__heading">
            {content && content.necessaryCookiesHeading}
          </h3>
          <div className="cookie-consent__update__type__text">
            {content &&
              content.necessaryCookiesText &&
              content.necessaryCookiesText.raw &&
              documentToReactComponents(
                JSON.parse(content.necessaryCookiesText.raw),
                MarkupOptions
              )}
          </div>
        </div>
        <div className="cookie-consent__update__type">
          <h3 className="cookie-consent__update__type__heading">
            <label className="toggle">
              <input
                checked={settings.analytics}
                className="toggle__input"
                id="cookieConsentAnalytics"
                onChange={handleChangeAnalytics}
                type="checkbox"
              />
              <span className="toggle__label">
                {content && content.analyticsCookiesHeading}
              </span>
              <span className="toggle__switch" />
            </label>
          </h3>
          <div className="cookie-consent__update__type__text">
            {content &&
              content.analyticsCookiesText &&
              content.analyticsCookiesText.raw &&
              documentToReactComponents(
                JSON.parse(content.analyticsCookiesText.raw),
                MarkupOptions
              )}
          </div>
        </div>
        <button
          className="btn cookie-consent__update__button"
          onClick={handleSave}
          ref={updateButton}
          type="button"
        >
          {content.updateButtonText}
        </button>
      </div>
    </div>
  );
};
