import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { MarkupOptions } from '../../utilities';

import './attributesList.scss';

interface PropsTypes {
  heading?: string;
  text?: any;
}

export const AttributesList = (props: PropsTypes) => {
  const data = useStaticQuery(graphql`
    query attributesListQuery {
      attributes: allContentfulScreeningType(
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          name
          image {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          alias
          description {
            raw
          }
        }
      }
    }
  `);

  return (
    <div className="contain">
      <div className="contain__column">
        {props.heading && (
          <h2 className="heading-highlighted">{props.heading}</h2>
        )}

        {props.text && (
          <div>{documentToReactComponents(props.text, MarkupOptions)}</div>
        )}

        {data &&
          data.attributes &&
          data.attributes.nodes &&
          data.attributes.nodes.length > 0 && (
            <div className="attributes-list">
              {data.attributes.nodes.map((item: any, index: number) => (
                <div
                  className="attributes-list__item"
                  id={`attribute-${item.alias}`}
                  key={index}
                >
                  {item.name && (
                    <h2 className="attributes-list__item__title heading-highlighted">
                      {item.name}
                    </h2>
                  )}
                  {item.image && item.image.description && (
                    <img
                      alt={item.image.description}
                      loading="lazy"
                      className="attributes-list__item__image"
                      src={item.image.file.url}
                      height={item.image.file.details.image.height}
                      width={item.image.file.details.image.width}
                    />
                  )}
                  {item.description && (
                    <div className="attributes-list__item__text accordion js-accordion">
                      {documentToReactComponents(
                        JSON.parse(item.description.raw),
                        MarkupOptions
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
      </div>
    </div>
  );
};
