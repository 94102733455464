import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { MarkupOptions } from '../../utilities';

import './newsletter.scss';

export const Newsletter = () => {
  const data = useStaticQuery(graphql`
    query newsletterQuery {
      attributes: allContentfulScreeningType(
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          name
        }
      }
      newsletter: contentfulNewsletter {
        buttonText
        emailFieldLabel
        emailFieldName
        firstNameFieldLabel
        firstNameFieldName
        heading
        lastNameFieldLabel
        lastNameFieldName
        mailchimpFormUrl
        mailchimpNotice {
          raw
        }
        marketingEmailFieldLabel
        marketingEmailFieldName
        marketingHeading
        marketingNote {
          marketingNote
        }
        marketingText {
          marketingText
        }
        messageFormError {
          messageFormError
        }
        messageSignupError {
          messageSignupError
        }
        messageSuccess {
          raw
        }
        regionFieldLabel
        regionFieldName
        screeningTypeFieldLabel
        screeningTypeFieldName
        text {
          raw
        }
      }
    }
  `);

  if (
    typeof data === 'undefined' ||
    data === null ||
    typeof data.newsletter === 'undefined' ||
    data.newsletter === null
  ) {
    return null;
  }

  const initialFields: any = {
    email: {
      label: data.newsletter.emailFieldLabel,
      name: data.newsletter.emailFieldName,
      required: true,
      value: '',
    },
    firstName: {
      label: data.newsletter.firstNameFieldLabel,
      name: data.newsletter.firstNameFieldName,
      required: true,
      value: '',
    },
    lastName: {
      label: data.newsletter.lastNameFieldLabel,
      name: data.newsletter.lastNameFieldName,
      required: true,
      value: '',
    },
    marketingEmail: {
      checked: false,
      label: data.newsletter.marketingEmailFieldLabel,
      name: data.newsletter.marketingEmailFieldName,
      required: true,
    },
    region: {
      label: data.newsletter.regionFieldLabel,
      name: data.newsletter.regionFieldName,
      required: false,
      value: '',
    },
    screeningType: {
      label: data.newsletter.screeningTypeFieldLabel,
      name: data.newsletter.screeningTypeFieldName,
      required: false,
      value: '',
    },
  };

  const [fields, setFields] = React.useState(initialFields);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [invalidFields, setInvalidFields] = React.useState([]);

  const handleSubmit = (evt: any) => {
    evt.preventDefault();

    const tempFields: any = [];

    for (const prop in fields) {
      if (fields.hasOwnProperty(prop)) {
        const field = fields[prop];

        if (
          (field.required === true &&
            typeof field.checked !== 'undefined' &&
            field.checked !== null &&
            field.checked === false) ||
          (field.required === true && field.value === '')
        ) {
          tempFields.push(field.label);
        }
      }
    }

    setInvalidFields(tempFields);

    if (tempFields.length > 0) {
      return;
    }

    evt.target.disabled = true;

    const url = evt.target.action.replace('/post', '/post-json') + '&c=?';

    const jQuery = require('jquery');

    jQuery.ajax({
      cache: false,
      data: jQuery(evt.target).serialize(),
      dataType: 'json',
      error: (error: any) => {
        // console.log(error);
        evt.target.disabled = false;
        setIsError(true);
      },
      success: (response: any) => {
        // console.log(response);
        setIsSuccess(true);
      },
      type: 'get',
      url,
    });
  };

  const handleChange = (evt: any) => {
    const tempFields: any = {
      ...fields,
    };

    for (const prop in tempFields) {
      if (
        tempFields.hasOwnProperty(prop) &&
        tempFields[prop].name === evt.target.name
      ) {
        if (
          typeof tempFields[prop].checked !== 'undefined' &&
          tempFields[prop].checked !== null
        ) {
          tempFields[prop].checked = evt.target.checked;
        } else {
          tempFields[prop].value = evt.target.value;
        }
        break;
      }
    }

    setInvalidFields([]);
    setFields(tempFields);
  };

  return (
    <div className="newsletter">
      <div className="contain">
        <div className="contain__column">
          {data.newsletter.heading && (
            <h2 className="newsletter__heading heading-highlighted">
              {data.newsletter.heading}
            </h2>
          )}

          {isSuccess === false && (
            <div id="mc_embed_signup">
              <form
                action={data.newsletter.mailchimpFormUrl}
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="newsletter__form"
                target="_blank"
                noValidate={true}
                onSubmit={handleSubmit}
              >
                <div id="mc_embed_signup_scroll">
                  {data.newsletter.text && (
                    <div className="newsletter__form__text">
                      {documentToReactComponents(
                        JSON.parse(data.newsletter.text.raw),
                        MarkupOptions
                      )}
                    </div>
                  )}
                  <div className="newsletter__form__field">
                    <label
                      className="newsletter__form__field__label"
                      htmlFor={`mce-${fields.email.name}`}
                    >
                      {fields.email.label} <small>(required)</small>
                    </label>
                    <input
                      type="email"
                      name={fields.email.name}
                      className="newsletter__form__field__input"
                      id={`mce-${fields.email.name}`}
                      required={fields.email.required}
                      onChange={handleChange}
                      value={fields.email.value}
                    />
                  </div>
                  <div className="newsletter__form__columns">
                    <div className="newsletter__form__field">
                      <label
                        className="newsletter__form__field__label"
                        htmlFor={`mce-${fields.firstName.name}`}
                      >
                        {fields.firstName.label} <small>(required)</small>
                      </label>
                      <input
                        type="text"
                        name={fields.firstName.name}
                        className="newsletter__form__field__input"
                        id={`mce-${fields.firstName.name}`}
                        required={fields.firstName.required}
                        onChange={handleChange}
                        value={fields.firstName.value}
                      />
                    </div>
                    <div className="newsletter__form__field">
                      <label
                        className="newsletter__form__field__label"
                        htmlFor={`mce-${fields.lastName.name}`}
                      >
                        {fields.lastName.label} <small>(required)</small>
                      </label>
                      <input
                        type="text"
                        name={fields.lastName.name}
                        className="newsletter__form__field__input"
                        id={`mce-${fields.lastName.name}`}
                        required={fields.lastName.required}
                        onChange={handleChange}
                        value={fields.lastName.value}
                      />
                    </div>
                    <div className="newsletter__form__field">
                      <label
                        className="newsletter__form__field__label"
                        htmlFor={`mce-${fields.screeningType.name}`}
                      >
                        {fields.screeningType.label}
                      </label>
                      <select
                        name={fields.screeningType.name}
                        className="newsletter__form__field__input"
                        id={`mce-${fields.screeningType.name}`}
                        onChange={handleChange}
                        value={fields.screeningType.value}
                      >
                        <option value="">Please choose</option>
                        <option value="All">All Types</option>
                        {data &&
                          data.attributes &&
                          data.attributes.nodes &&
                          data.attributes.nodes.length > 0 &&
                          data.attributes.nodes.map(
                            (item: any, index: number) => (
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            )
                          )}
                      </select>
                    </div>
                    {NewsletterRegions && (
                      <div className="newsletter__form__field">
                        <label
                          className="newsletter__form__field__label"
                          htmlFor={`mce-${fields.region.name}`}
                        >
                          {fields.region.label}
                        </label>
                        <select
                          name={fields.region.name}
                          className="newsletter__form__field__input"
                          id={`mce-${fields.region.name}`}
                          onChange={handleChange}
                          value={fields.region.value}
                        >
                          <option value="">Please choose</option>
                          {NewsletterRegions.map(
                            (region: any, index: number) => (
                              <option key={index} value={region.id}>
                                {region.name}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    )}
                  </div>
                  <fieldset
                    id="mergeRow-gdpr"
                    className="newsletter__form__group"
                  >
                    <legend className="newsletter__form__group__heading">
                      {data.newsletter.marketingHeading}
                    </legend>
                    {data.newsletter.marketingText && (
                      <p className="newsletter__form__group__text">
                        {data.newsletter.marketingText.marketingText}
                      </p>
                    )}
                    <div className="newsletter__form__field">
                      <label
                        className="newsletter__form__field__checkbox"
                        htmlFor={`mce-${fields.marketingEmail.name}`}
                      >
                        <input
                          type="checkbox"
                          id={`mce-${fields.marketingEmail.name}`}
                          name={fields.marketingEmail.name}
                          value="Y"
                          className="newsletter__form__field__checkbox__input"
                          required={fields.marketingEmail.required}
                          onChange={handleChange}
                          checked={fields.marketingEmail.checked}
                        />
                        <span className="newsletter__form__field__checkbox__label">
                          {fields.marketingEmail.label}{' '}
                          <small>(required)</small>
                        </span>
                      </label>
                    </div>
                    {data.newsletter.marketingNote && (
                      <p className="newsletter__form__group__note">
                        {data.newsletter.marketingNote.marketingNote}
                      </p>
                    )}
                  </fieldset>
                  <div style={{ position: 'absolute', left: '-5000px' }}>
                    <input
                      aria-label="please ignore this field"
                      type="text"
                      name="b_bfc3ec911a91cff696da02829_577cbc2856"
                      className="-1"
                    />
                  </div>
                  {data.newsletter.mailchimpNotice && (
                    <div className="newsletter__form__message">
                      {documentToReactComponents(
                        JSON.parse(data.newsletter.mailchimpNotice.raw),
                        MarkupOptions
                      )}
                    </div>
                  )}
                  <div className="newsletter__form__field">
                    <input
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="btn newsletter__form__field__button"
                    />
                  </div>
                  <div id="mce-responses">
                    <div
                      aria-atomic="true"
                      className="newsletter__form__response"
                      id="mce-error-response"
                      role="alert"
                      style={{ display: 'none' }}
                    />
                    <div
                      aria-atomic="true"
                      className="newsletter__form__response"
                      id="mce-success-response"
                      role="alert"
                      style={{ display: 'none' }}
                    />
                    {invalidFields.length > 0 && (
                      <div className="newsletter__form__response" role="alert">
                        <p>
                          {data.newsletter.messageFormError.messageFormError}
                        </p>

                        <ul>
                          {invalidFields.map((label: string, index: number) => {
                            return <li key={index}>{label}</li>;
                          })}
                        </ul>
                      </div>
                    )}
                    {isError && (
                      <div className="newsletter__form__response" role="alert">
                        {data.newsletter.messageSignupError.messageSignupError}
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          )}

          {isSuccess && (
            <div role="alert" className="newsletter__success">
              {data.newsletter.messageSuccess &&
                documentToReactComponents(
                  JSON.parse(data.newsletter.messageSuccess.raw),
                  MarkupOptions
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const NewsletterRegions = [
  {
    id: '1',
    name: 'Scotland',
  },
  {
    id: '2',
    name: 'Wales',
  },
  {
    id: '4',
    name: 'Northern Ireland',
  },
  {
    id: '8',
    name: 'South West',
  },
  {
    id: '16',
    name: 'South East',
  },
  {
    id: '32',
    name: 'London',
  },
  {
    id: '64',
    name: 'Midlands',
  },
  {
    id: '128',
    name: 'North West',
  },
  {
    id: '256',
    name: 'North East',
  },
  {
    id: '512',
    name: 'East Anglia',
  },
];
