import * as React from 'react';
import { AttributesList } from './attributes/attributesList';
import { AttributesSummary } from './attributes/attributesSummary';
import { Carousel } from './carousel/carousel';
import { CinemaFinder } from './cinema-finder/cinema-finder';
import { Contact } from './contact/contact';
import { FilmFinder } from './film-finder/film-finder';
import { FilmResults } from './film-finder/film-results';
import { List } from './list/list';
import { Sitemap } from './sitemap/sitemap';

interface PropsTypes {
  item: {
    componentHeading?: string;
    componentText?: {
      raw?: string;
    };
    componentType: string;
  };
  pageContext?: any;
}

export const Component = (props: PropsTypes) => {
  if (typeof props.item !== 'undefined' && props.item !== null) {
    switch (props.item.componentType) {
      case 'cinemas list':
        return (
          <CinemaFinder
            heading={props.item.componentHeading}
            text={props.item.componentText && props.item.componentText.raw}
          />
        );
      case 'contact us form':
        return (
          <Contact
            heading={props.item.componentHeading}
            text={props.item.componentText && props.item.componentText.raw}
          />
        );
      case 'film finder':
        return (
          <FilmFinder
            heading={props.item.componentHeading}
            isComponent={true}
            text={props.item.componentText && props.item.componentText.raw}
          />
        );
      case 'film finder results':
        return (
          <FilmResults
            heading={props.item.componentHeading}
            text={props.item.componentText && props.item.componentText.raw}
          />
        );
      case 'certification list':
      case 'partners list':
        return (
          <List
            heading={props.item.componentHeading}
            pageContext={{
              type: props.item.componentType,
            }}
            text={props.item.componentText && props.item.componentText.raw}
          />
        );
      case 'news list':
        return (
          <List
            heading={props.item.componentHeading}
            pageContext={props.pageContext}
            text={props.item.componentText && props.item.componentText.raw}
          />
        );
      case 'partners carousel':
        return (
          <Carousel
            heading={props.item.componentHeading}
            text={props.item.componentText && props.item.componentText.raw}
          />
        );
      case 'sitemap':
        return (
          <Sitemap
            heading={props.item.componentHeading}
            text={props.item.componentText && props.item.componentText.raw}
          />
        );
      case 'types of screenings list':
        return (
          <AttributesList
            heading={props.item.componentHeading}
            text={props.item.componentText && props.item.componentText.raw}
          />
        );
      case 'types of screenings summary list':
        return (
          <AttributesSummary
            heading={props.item.componentHeading}
            text={props.item.componentText && props.item.componentText.raw}
          />
        );
    }
  }

  return null;
};
